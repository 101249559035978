<template>
  <EditEntity
    :fields="fields"
    :save-url="saveUrl"
    :get-url="getUrl"
    :id="$route.params.id"
    :buttons="buttons"
    @updateIsDataSaved="updateIsDataSaved"
  ></EditEntity>
</template>
<script>
import { defineComponent } from "vue";
import EditEntity from "@/components/EditEntity";

export default defineComponent({
  name: "admin-mod",
  components: {
    EditEntity,
  },
  data: function () {
    return {
      getUrl: "admin/mods/",
      saveUrl: "api/admin/mods/save",
      isDataSaved: true,

      fields: [
        {
          name: "Active",
          value: "active",
          type: "boolean",
        },
        {
          name: "Title",
          value: "title",
        },
        {
          name: "Transliterated title (displays in URL!)",
          value: "transliteratedTitle",
        },

        {
          name: "Game Type",
          value: "game",
          type: "select",
          options: [
            {
              value: 0,
              name: "The First Encounter",
            },
            {
              value: 1,
              name: "The Second Encounter",
            },
            {
              value: 2,
              name: "TFE/TSE",
            },
          ],
        },
        {
          name: "Mod Type",
          value: "type",
          type: "select",
          options: [
            {
              value: 0,
              name: this.$t("mod_type_map"),
            },
            {
              value: 1,
              name: this.$t("mod_type_mod"),
            },
            {
              value: 2,
              name: this.$t("mod_type_skin"),
            },
            {
              value: 3,
              name: this.$t("mod_type_resource"),
            },
            {
              value: 4,
              name: this.$t("mod_type_dll"),
            },
          ],
        },
        {
          name: "Subcategory",
          value: "subcategory",
          type: "select",
          options: [
            {
              value: 0,
              name: this.$t("singlePlayer"),
            },
            {
              value: 1,
              name: this.$t("cooperative"),
            },
            {
              value: 2,
              name: this.$t("singleAndCoop"),
            },
            {
              value: 3,
              name: this.$t("deathmatch"),
            },
            {
              value: 4,
              name: this.$t("allGameModes"),
            },
          ],
        },
        {
          name: "Description",
          value: "description",
          type: "textarea-i18n",
        },

        {
          name: "Original created date (YYYY-MM-DD)",
          value: "originalCreatedDate",
          type: "datepicker",
        },

        {
          name: "Authors",
          value: "modAuthors",
          type: "entity-list",
          fields: [
            {
              name: "Author",
              value: "authorId",
              type: "suggestion",
              url: "admin/authors/suggestion",
            },
          ],
        },
        {
          name: "Links",
          value: "modLinks",
          type: "entity-list",
          imgProcessor: function (item) {
            return {
              valid: item.type === 1 && item.id != null,
              url: item.url,
            };
          },
          fields: [
            {
              name: "Url",
              value: "url",
            },
            {
              name: "Preview Url",
              value: "previewUrl",
            },
            {
              name: "Type",
              value: "type",
              type: "select",
              options: [
                {
                  value: 0,
                  name: "File",
                },
                {
                  value: 1,
                  name: "Screenshot",
                },
                {
                  value: 2,
                  name: "Youtube video",
                },
                {
                  value: 3,
                  name: "MP4 video",
                },
              ],
            },
            {
              name: "Or upload file",
              value: "url",
              previewValue: "previewUrl",
              previewCondition: function (dto) {
                return dto["type"] === 1 || dto["type"] === 3; // preview only for screenshots
              },
              type: "upload",
            },
          ],
        },
        {
          name: "Tags",
          value: "modTags",
          type: "entity-list",
          fields: [
            {
              name: "Tag",
              value: "tagId",
              type: "suggestion",
              loadAll: true,
              url: "admin/tags/suggestion",
            },
          ],
        },
        {
          name: "Size",
          value: "size",
        },
        {
          name: "Version",
          value: "version",
        },
        {
          name: "Mod Folder",
          value: "modFolder",
        },
      ],

      buttons: [
        {
          name: "Publish",
          type: "saveWithArgs",
          args: "publish=true",
        },
        {
          name: "Open mod page",
          action: function(dto) {
            window.open("/mod/" + (dto.transliteratedTitle ? dto.transliteratedTitle : dto.id), "_blank");
          },
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("beforeunload", this.beforeUnload);
  },

  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeUnload); // Очистите обработчик, чтобы избежать утечек памяти
  },

  beforeRouteLeave(to, from, next) {
    if (!this.isDataSaved) {
      const answer = window.confirm(
        "У вас есть несохраненные изменения. Вы уверены, что хотите уйти?"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    beforeUnload(event) {
      if (!this.isDataSaved) {
        const message =
          "У вас есть несохраненные изменения. Вы уверены, что хотите покинуть страницу?";
        event.returnValue = message; // Стандартное сообщение для разных браузеров
        return message; // Некоторые браузеры требуют возврата сообщения
      }
    },
    updateIsDataSaved: function (newValue) {
      this.isDataSaved = newValue;
    },
  },
});
</script>
